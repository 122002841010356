module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","zh-TW","zh-CN"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://joeywang.tw","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid+","getLanguageFromPath":true,"excludeLanguages":["zh-TW","zh-CN"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Joey Wang","short_name":"Joey Wang","start_url":"/","background_color":"#EEEEEE","theme_color":"#26A69A","display":"standalone","icon":"src/static/icon/icon512.png","icons":[{"src":"src/static/icon/favicon.ico","sizes":"48x48","type":"image/x-icon"},{"src":"src/static/icon/icon16.png","sizes":"16x16","type":"image/png"},{"src":"src/static/icon/icon32.png","sizes":"32x32","type":"image/png"},{"src":"src/static/icon/icon180.png","sizes":"180x180","type":"image/png"},{"src":"src/static/icon/icon192.png","sizes":"192x192","type":"image/png"},{"src":"src/static/icon/icon512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d7bbebeaff08269a750063fb26b63e2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

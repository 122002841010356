exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=C:/Users/wangj/Documents/blog/joeywang4.github.io/blog/pubu-vulnerability/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-zh-cn-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=C:/Users/wangj/Documents/blog/joeywang4.github.io/blog/pubu-vulnerability/index.zh-CN.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-zh-cn-mdx" */),
  "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-zh-tw-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=C:/Users/wangj/Documents/blog/joeywang4.github.io/blog/pubu-vulnerability/index.zh-TW.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-blog-pubu-vulnerability-index-zh-tw-mdx" */)
}

